import React from 'react';
import {
    Image
} from "react-bootstrap";
import fondoPrincipal from "../../assets/ETAPA1.png";
import etiquetaMenu from "../../assets/menu/ETAPA1.png"

import logoEtapa1 from "../../assets/botones/etapa1_modulos/E1_M1/E1_MOD1.png";
import logoEtapa2 from "../../assets/botones/etapa1_modulos/E1_M2/E1_MOD2.png";
import logoEtapa3 from "../../assets/botones/etapa1_modulos/E1_M3/E1_MOD3.png";
import logoEtapa4 from "../../assets/botones/etapa1_modulos/E1_M4/E1_MOD4.png";
import logoEtapa5 from "../../assets/botones/etapa1_modulos/E1_M5/E1_MOD5.png";
import logoEtapa6 from "../../assets/botones/etapa1_modulos/E1_M6/E1_MOD6.png";
import logoEtapa7 from "../../assets/botones/etapa1_modulos/E1_M7/E1_MOD7.png";
import logoEtapa8 from "../../assets/botones/etapa1_modulos/E1_M8/E1_MOD8.png";
import logoEtapa9 from "../../assets/botones/etapa1_modulos/E1_M9/E1_MOD9.png";
/*
import logoHoverEtapa1 from "../../assets/botones/etapa1_modulos/E1_M1/E1_MOD1_SELECT.png";
import logoHoverEtapa2 from "../../assets/botones/etapa1_modulos/E1_M2/E1_MOD2_SELECT.png";
import logoHoverEtapa3 from "../../assets/botones/etapa1_modulos/E1_M3/E1_MOD3_SELECT.png";
import logoHoverEtapa4 from "../../assets/botones/etapa1_modulos/E1_M4/E1_MOD4_SELECT.png";
import logoHoverEtapa5 from "../../assets/botones/etapa1_modulos/E1_M5/E1_MOD5_SELECT.png";
import logoHoverEtapa6 from "../../assets/botones/etapa1_modulos/E1_M6/E1_MOD6_SELECT.png";
import logoHoverEtapa7 from "../../assets/botones/etapa1_modulos/E1_M7/E1_MOD7_SELECT.png";
import logoHoverEtapa8 from "../../assets/botones/etapa1_modulos/E1_M8/E1_MOD8_SELECT.png";
import logoHoverEtapa9 from "../../assets/botones/etapa1_modulos/E1_M9/E1_MOD9_SELECT.png";
*/
import {
    useHistory
} from "react-router-dom";

import botonContinuar from "../../assets/botones/continuar/BOTON_CONTINUAL.png";
import botonInicio from "../../assets/botones/inicio/BOTON_INICIO.png";

import "./Etapa1.scss";

function Etapa1(props) {
    const history = useHistory();

    //Funciones que controlan el boton de menu y continuar
    const menu = () => {
        history.push("/");
    }

    const continuar = () => {
        history.push("/Etapa2");
    }

    // Funciones para redireccionar hacia los modulos
    const modulo1E1 = () => {
        window.open("https://modulo1.micavirtual.com", "_blank")
    }

    const modulo2E1 = () => {
        window.open("https://modulo2.micavirtual.com", "_blank")
    }

    const modulo3E1 = () => {
        window.open("https://modulo3.micavirtual.com", "_blank")
    }

    const modulo4E1 = () => {
        window.open("https://modulo4.micavirtual.com", "_blank")
    }

    const modulo5E1 = () => {
        window.open("https://modulo5.micavirtual.com", "_blank")
    }

    const modulo6E1 = () => {
        window.open("https://modulo6.micavirtual.com", "_blank")
    }

    const modulo7E1 = () => {
        window.open("https://modulo7.micavirtual.com", "_blank")
    }

    const modulo8E1 = () => {
        window.open("https://modulo8.micavirtual.com", "_blank")
    }

    const modulo9E1 = () => {
        window.open("https://modulo9.micavirtual.com", "_blank")
    }

    return (
        <>
            <Image
                src={etiquetaMenu}
                className="etiquetaMenu"
            />
            <Image
                src={fondoPrincipal}
                className="fondoPrincipal"
            />
            <div className="botonesEtapa1">
                <Image
                    src={logoEtapa1}
                    className="etapa1"
                    onClick={() => {
                        modulo1E1()
                    }}
                />

                <Image
                    src={logoEtapa2}
                    className="etapa2"
                    onClick={() => {
                        modulo2E1()
                    }}
                />

                <Image
                    src={logoEtapa3}
                    className="etapa3"
                    onClick={() => {
                        modulo3E1()
                    }}
                />

                <Image
                    src={logoEtapa4}
                    className="etapa4"
                    onClick={() => {
                        modulo4E1()
                    }}
                />

                <Image
                    src={logoEtapa5}
                    className="etapa5"
                    onClick={() => {
                        modulo5E1()
                    }}
                />

                <Image
                    src={logoEtapa6}
                    className="etapa6"
                    onClick={() => {
                        modulo6E1()
                    }}
                />

                <Image
                    src={logoEtapa7}
                    className="etapa7"
                    onClick={() => {
                        modulo7E1()
                    }}
                />

                <Image
                    src={logoEtapa8}
                    className="etapa8"
                    onClick={() => {
                        modulo8E1()
                    }}
                />

                <Image
                    src={logoEtapa9}
                    className="etapa9"
                    onClick={() => {
                        modulo9E1()
                    }}
                />

                <Image
                    src={botonInicio}
                    className="inicio"
                    onClick={() => {
                        menu()
                    }}
                />

                <Image
                    src={botonContinuar}
                    className="continuar"
                    onClick={() => {
                        continuar()
                    }}
                />

            </div>
        </>
    );
}

export default Etapa1;
