import React from 'react';
import {Link, BrowserRouter, Router} from "react-router-dom";
import {
    Image
} from "react-bootstrap";
import imagenInicio from "../../assets/menu/inicio/INICIO.png";
import imagenQueEsMICA from "../../assets/menu/queesMica/M_QUEES.png";
import imagenEtapa1 from "../../assets/menu/etapa1/M_ETAPA1.png";
import imagenEtapa2 from "../../assets/menu/etapa2/M_ETAPA2.png";
import imagenNuevosModulos from "../../assets/menu/nuevosModulos/M_NUEVOSMODULOS.png";
import imagenContacto from "../../assets/menu/contacto/M_CONTACTO.png";
import { slide as MenuHamburguer } from "react-burger-menu";
import "./Menu.scss";

function Menu(props) {
    return (
        <>
            <MenuHamburguer>
                <BrowserRouter>
                    <Link to="/">
                        <Image src={imagenInicio} className="imagenInicio" />
                    </Link>
                    <Link to="/QueEsMica">
                        <Image src={imagenQueEsMICA} className="imagenQueEsMICA" />
                    </Link>
                    <Link to="/Etapa1">
                        <Image src={imagenEtapa1} className="imagenEtapa1" />
                    </Link>
                    <Link to="/Etapa2">
                        <Image src={imagenEtapa2} className="imagenEtapa2" />
                    </Link>
                    <Link to="/NuevosModulos">
                        <Image src={imagenNuevosModulos} className="imagenNuevosModulos" />
                    </Link>
                    <Link to="/Contacto">
                        <Image src={imagenContacto} className="imagenContacto" />
                    </Link>
                </BrowserRouter>
            </MenuHamburguer>
        </>
    );
}

export default Menu;
