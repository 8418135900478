import React from 'react';
import {
    Button,
    Image
} from "react-bootstrap";
import fondoPrincipal from "../../assets/CONTAC6O.jpg";
import etiquetaMenu from "../../assets/menu/CONTACTO.png"
import "./Contacto.scss";

function Contacto(props) {
    return (
        <>
            <Image
                src={etiquetaMenu}
                className="etiquetaMenu"
            />
            <Image
                src={fondoPrincipal}
                className="fondoPrincipal"
            />
        </>
    );
}

export default Contacto;
