import React from 'react';
import {
    Image
} from "react-bootstrap";
import splashScreen1 from "../../assets/LOGOS.jpg";
import "./SplashScreen.scss";

export default function SplashScreen(props) {
    const { display } = props;
    return (
        <>
            <Image
                src={splashScreen1}
                className={display ? "imagenSplash1" : "ocultaImagenSplash1"}
            />
        </>
    );
}
