import React, { useState, useEffect, useRef, useCallback } from 'react';
import './App.scss';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Inicio from "./pages/Inicio";
import Contacto from "./pages/Contacto";
import Etapa1 from "./pages/Etapa1";
import Etapa2 from "./pages/Etapa2";
import NuevosModulos from "./pages/NuevosModulos";
import QueEsMica from "./pages/QueEsMICA";
import Routing from "./routers/Routing";
import Menu from "./components/Menu";
import {map} from "lodash";
import configRouting from "./routers/configRouting";
import {Image} from "react-bootstrap";
import imagenInicio from "./assets/menu/inicio/INICIO.png";
import imagenQueEsMICA from "./assets/menu/queesMica/M_QUEES.png";
import imagenEtapa1 from "./assets/menu/etapa1/M_ETAPA1.png";
import imagenEtapa2 from "./assets/menu/etapa2/M_ETAPA2.png";
import imagenNuevosModulos from "./assets/menu/nuevosModulos/M_NUEVOSMODULOS.png";
import imagenContacto from "./assets/menu/contacto/M_CONTACTO.png";

import { slide as MenuHamburguer } from "react-burger-menu";
import SplashScreen from "./components/SplashScreen";
import Loading from "./components/Loading";

//Funcion para el tiempo de espera de los splash screen
const useDebouncedCallback = (func, wait) => {
    const timeout = useRef();

    return useCallback(
        (...args) => {
            const later = () => {
                clearTimeout(timeout.current);
                func(...args);
            };

            clearTimeout(timeout.current);
            timeout.current = setTimeout(later, wait);
        },
        [func, wait]
    );
};

export default function App() {
    const [primerSplashScreen, setPrimerSplashScreen] = useState(false);
    const [muestraSegundoSlider, setMuestraSegundoSlider] = useState(false);
    const [segundoSplashScreen, setSegundoSplashScreen] = useState(false);

    //Inicia el primer Splash Screen y determina el fin
    useEffect(() => {
        const timer = setTimeout(() => {
            setPrimerSplashScreen(false);
        }, 4000);
        setPrimerSplashScreen(true);
        setMuestraSegundoSlider(true);
        return () => clearTimeout(timer);
    }, []);

    //Inicia el segundo Splash Screen y determina el fin
    useEffect(() => {
        const timer = setTimeout(() => {
            setSegundoSplashScreen(false);
        }, 7000);
        setSegundoSplashScreen(true);
        return () => clearTimeout(timer);
    }, []);



    return (
    <>
        <div className="contenedorPrimerSlider">
           <SplashScreen display={primerSplashScreen} />
        </div>
        {!primerSplashScreen &&
            (
                <div className={segundoSplashScreen ? "contenedorSegundoSlider" : "ocultaLoading"}>
                    <Loading display={segundoSplashScreen} validaVista={primerSplashScreen} />
                </div>
            )
        }

        {!primerSplashScreen &&
            !segundoSplashScreen &&
            (
                <>
                    <div className="contenedorPrincipal">
                        <Router>
                            <div>
                                <MenuHamburguer>
                                    <Link to="/">
                                        <h1>Inicio</h1>
                                    </Link>
                                    <Link to="/QueEsMica">
                                        <h2>¿Qué es MICA?</h2>
                                    </Link>
                                    <Link to="/RecorridoVirtual">
                                        <h2>Recorrido virtual</h2>
                                    </Link>
                                    <Link to="/Etapa1">
                                        <h2>Etapa 1</h2>
                                    </Link>
                                    <Link to="/Etapa2">
                                        <h2>Etapa 2</h2>
                                    </Link>
                                    <Link to="/NuevosModulos">
                                        <h2>Nuevos modulos</h2>
                                    </Link>
                                    <Link to="/Contacto">
                                        <h2>Contacto</h2>
                                    </Link>
                                </MenuHamburguer>


                                <Switch>
                                    {map(configRouting, (route, index) => (
                                        <Route key={index} path={route.path} exact={route.exact}>
                                            <route.page />
                                        </Route>
                                    ))}
                                </Switch>
                            </div>
                        </Router>
                    </div>
                </>
            )
        }
    </>
  );
}
