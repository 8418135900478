import React from 'react';
import {
    Image
} from "react-bootstrap";
import fondoPrincipal from "../../assets/ETAPA3.png";
import etiquetaMenu from "../../assets/menu/NUEVOSMODULOS.png"

import logoEtapa1 from "../../assets/botones/etapa3_modulos/E3_M1/E3_MOD1.png";
import logoEtapa2 from "../../assets/botones/etapa3_modulos/E3_M2/E3_MOD2.png";

import "./NuevosModulos.scss";
import {
    useHistory
} from "react-router-dom";

import botonContinuar from "../../assets/botones/continuar/BOTON_CONTINUAL.png";
import botonInicio from "../../assets/botones/inicio/BOTON_INICIO.png";

function NuevosModulos(props) {
    const history = useHistory();

    //Funciones que controlan el boton de menu y continuar
    const menu = () => {
        history.push("/");
    }

    // Funciones para redireccionar hacia los modulos
    const modulo1E3 = () => {
        window.open("https://etapa3modulo2.micavirtual.com", "_blank")
    }

    const modulo2E3 = () => {
        window.open("https://etapa3modulo1.micavirtual.com", "_blank")
    }

    return (
        <>
            <Image
                src={etiquetaMenu}
                className="etiquetaMenu"
            />
            <Image
                src={fondoPrincipal}
                className="fondoPrincipal"
            />

            <div className="botonesEtapa3">
                <Image
                    src={logoEtapa1}
                    className="etapa3M1"
                    onClick={() => {
                        modulo1E3()
                    }}
                />

                <Image
                    src={logoEtapa2}
                    className="etapa3M2"
                    onClick={() => {
                        modulo2E3()
                    }}
                />

                <Image
                    src={botonInicio}
                    className="inicio"
                    onClick={() => {
                        menu()
                    }}
                />

            </div>

        </>
    );
}

export default NuevosModulos;
