import React, { useState } from 'react';
import {
    Button,
    Image
} from "react-bootstrap";
import { withRouter } from "react-router-dom";
import fondoPrincipal from "../../assets/fondoPrincipal.png";
import etiquetaMenu from "../../assets/menu/MICAVIRTUAL.png";

//Textos de bienvenida
import bienvenido from "../../assets/textos/bienvenidos.png";
import textoBienvenida from "../../assets/textos/textoBienvenida.png";

import botonEtapa1  from "../../assets/botones/etapa1/BOTON_ETAPA1.png";
import botonEtapa2  from "../../assets/botones/etapa2/BOTON_ETAPA3_1.png"
import botonEtapa3 from "../../assets/botones/etapa3/BOTON_ETAPA3.png"
import botonRecorridoVirtual from "../../assets/botones/recorrido/BOTON_RECORRIDO.png";
import textoRecorrido from "../../assets/textos/texto_recorrido.png";
import textoEtapa1 from "../../assets/textos/texto_etapa1.png";
import textoEtapa2 from "../../assets/textos/texto_etapa2.png";
import textoEtapa3 from "../../assets/textos/texto_etapa3.png";
import animacionHidrobot from "../../assets/animhidrobot-1.gif";

//Iconos para al lada de hiddrobot
import aprende from "../../assets/iconos/aprende.png";
import conciencia from "../../assets/iconos/conciencia.png";
import diviertete from "../../assets/iconos/diviertete.png";
import interactua from "../../assets/iconos/interactua.png";
import juega from "../../assets/iconos/juega.png";

//Importaciones para efecto swipe
import SwiperCore, { EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import "./Inicio.scss";
import {
    BrowserRouter as Router,
    Link,
    useHistory
} from "react-router-dom";
import BasicModal from "../../components/Modal/BasicModal";
import VideoBienvenida from "../../components/VideoBienvenida";

SwiperCore.use([EffectCoverflow, Pagination]);

function Inicio(props) {
    //const { location, history } = props;
    const history = useHistory();

    //Para el modal
    const [showModal, setShowModal] = useState(false);
    const [contentModal, setContentModal] = useState(null);
    const [titulosModal, setTitulosModal] = useState(null);

    const recorridoVirtual = () => {
        history.push("/RecorridoVirtual");
    }

    const etapa1 = () => {
        history.push("/Etapa1");
    }

    const etapa2 = () => {
        history.push("/Etapa2");
    }

    const etapa3 = () => {
        history.push("/NuevosModulos");
    }

    const abrirModalVideoBienvenida = (content) => {
        setContentModal(content);
        setShowModal(true);
    }

    return (
        <>
            <Image
                src={etiquetaMenu}
                className="etiquetaMenu"
            />
            <Image
                src={fondoPrincipal}
                className="fondoPrincipal"
            />

            <div className="botonesPrincipales">
                <div className="bienvenida">
                    <Image src={bienvenido} className="bienvenidos" />
                    <Image src={textoBienvenida} className="textoBienvenida" />

                    <Button
                        className="botonVervideo"
                        onClick={() =>
                            abrirModalVideoBienvenida(<VideoBienvenida />)
                        }
                    >
                        Ver video bienvenida
                    </Button>

                    <hr className="invisibleHR"/>
                    <hr className="invisibleHR"/>
                    <Image src={animacionHidrobot} className="hidrobot" />
                    <div className="iconos">
                        <Image src={aprende} className="aprende" />
                        <Image src={conciencia} className="conciencia" />
                        <hr className="invisibleHR"/>
                        <Image src={diviertete} className="diviertete" />
                        <Image src={interactua} className="interactua" />
                        <hr className="invisibleHR" />
                        <Image src={juega} className="juega" />
                    </div>
                </div>

                <Router>
                    <Swiper
                        effect={"coverflow"}
                        grabCursor={true}
                        centeredSlides={true}
                        slidesPerView={"auto"}
                        coverflowEffect={{
                            rotate: 50,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: false,
                        }}
                        pagination={true}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <Button
                                className="recorridoVirtual"
                                onClick={() => recorridoVirtual()}
                            >
                                <Image src={botonRecorridoVirtual} className="botonRecorridoVirtual" />
                            </Button>
                            <Image src={textoRecorrido} className="textoRecorridoVirtual" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Button
                                className="etapa1"
                                onClick={() => etapa1()}
                            >
                                <Link to="/Etapa1">
                                    <Image src={botonEtapa1} className="botonEtapa1" />
                                </Link>
                            </Button>
                            <Image src={textoEtapa1} className="textoEtapa1" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Button
                                className="etapa2"
                                onClick={() => etapa2()}
                            >
                                <Link to="/Etapa2">
                                    <Image src={botonEtapa2} className="botonEtapa2" />
                                </Link>
                            </Button>
                            <Image src={textoEtapa2} className="textoEtapa2" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Button
                                className="etapa3"
                                onClick={() => etapa3()}
                            >
                                <Link to="/Etapa3">
                                    <Image src={botonEtapa3} className="botonEtapa3" />
                                </Link>
                            </Button>
                            <Image src={textoEtapa3} className="textoEtapa3" />
                        </SwiperSlide>
                    </Swiper>
                </Router>
            </div>


            <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
                {contentModal}
            </BasicModal>
        </>
    );
}

export default withRouter(Inicio);
