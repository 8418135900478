import React from 'react';
import {
    Button,
    Image
} from "react-bootstrap";
import fondoPrincipal from "../../assets/ETAPA3.png";
import etiquetaMenu from "../../assets/menu/RECORRIDOVIRTUAL.png"
import "./RecorridoVirtual.scss";
import {
    useHistory
} from "react-router-dom";
import botonInicio from "../../assets/botones/inicio/BOTON_INICIO.png";
import botonEtapa1 from "../../assets/botones/etapa1/BOTON_ETAPA1.png";
import textoEtapa1 from "../../assets/textos/texto_etapa1.png";
import botonEtapa2 from "../../assets/botones/etapa2/BOTON_ETAPA3_1.png";
import textoEtapa2 from "../../assets/textos/texto_etapa2.png";

function RecorridoVirtual(props) {
    const history = useHistory();

    //Funciones que controlan el boton de menu y continuar
    const menu = () => {
        history.push("/");
    }

    // Funciones para redireccionar hacia las etapas del recorrido
    const recorridoEtapa1 = () => {
        window.open("https://recorridoetapa1.micavirtual.com", "_blank")
    }

    const recorridoEtapa2 = () => {
        window.open("https://recorridoetapa2.micavirtual.com", "_blank")
    }


    return (
        <>
            <Image
                src={etiquetaMenu}
                className="etiquetaMenu"
            />
            <Image
                src={fondoPrincipal}
                className="fondoPrincipal"
            />

            <div className="botonesRecorrido">
                <Button
                    className="botonRecorridoEtapa1"
                    onClick={() => recorridoEtapa1()}
                >
                    <Image src={botonEtapa1} className="recorridoEtapa1" />
                </Button>
                <Image src={textoEtapa1} className="textoEtapa1" />

                <Button
                    className="botonRecorridoEtapa2"
                    onClick={() => recorridoEtapa2()}
                >
                    <Image src={botonEtapa2} className="recorridoEtapa2" />
                </Button>
                <Image src={textoEtapa2} className="textoEtapa2" />

                <Image
                    src={botonInicio}
                    className="inicio"
                    onClick={() => {
                        menu()
                    }}
                />
            </div>
        </>
    );
}

export default RecorridoVirtual;
