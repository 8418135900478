import Inicio from "../pages/Inicio";
import Contacto from "../pages/Contacto";
import Etapa1 from "../pages/Etapa1";
import Etapa2 from "../pages/Etapa2";
import NuevosModulos from "../pages/NuevosModulos";
import QueEsMica from "../pages/QueEsMICA";
import RecorridoVirtual from "../pages/RecorridoVirtual";

export default  [
    {
        path: "/RecorridoVirtual",
        exact: true,
        page: RecorridoVirtual
    },
    {
        path: "/QueEsMica",
        exact: true,
        page: QueEsMica
    },
    {
        path: "/NuevosModulos",
        exact: true,
        page: NuevosModulos
    },
    {
        path: "/Etapa1",
        exact: true,
        page: Etapa1
    },
    {
        path: "/Etapa2",
        exact: true,
        page: Etapa2
    },
    {
        path: "/Contacto",
        exact: true,
        page: Contacto
    },
    {
        path: "/",
        exact: true,
        page: Inicio
    }
]
