import React from 'react';
import videoBienvenida from "../../assets/mp4/bienvenida.mp4";

function VideoBienvenida(props) {
    return (
        <>
            <video loop autoPlay className="videoBienvenida">
                <source src={videoBienvenida} type="video/mp4" />
            </video>
        </>
    );
}

export default VideoBienvenida;
