import React, { useState, useEffect} from 'react';
import {
    Image, ProgressBar
} from "react-bootstrap";
import splashScreenLoading from "../../assets/PANTALLA_CARGA.jpg";
import "./Loading.scss";

function Loading(props) {
    const { display } = props;

    const [valorCarga, setValorCarga] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setValorCarga(valorAnterior => {
                const nuevoValor = valorAnterior + 20;

                if(nuevoValor === 100) {
                    clearInterval(interval);
                }

                return nuevoValor;
            });
        }, 500)
    }, []);

    console.clear();

    return (
        <>
            <Image
                src={splashScreenLoading}
                className={display ? "imagenSplashLoading" : "ocultaLoading"}
            />
            <ProgressBar animated now={valorCarga} className={display ? "barraLoading" : "ocultaLoading"} />
        </>
    );
}

export default Loading;
