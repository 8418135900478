import React from 'react';
import {
    Image
} from "react-bootstrap";
import fondoPrincipal from "../../assets/QUE_ES_MICA.jpg";
import etiquetaMenu from "../../assets/menu/QUEES.png";
import L from 'leaflet';
import {MapContainer, TileLayer, Marker, Popup} from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import "leaflet/dist/images/marker-icon-2x.png";

import "./QueEsMICA.scss";

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;


function QueEsMica(props) {

    const position = [20.3912195, -100.0047134]



    return (
        <>
            <Image
                src={etiquetaMenu}
                className="etiquetaMenu"
            />
            <Image
                src={fondoPrincipal}
                className="fondoPrincipal"
            />
            <div className="mapa">
                <MapContainer center={position} zoom={16} scrollWheelZoom={false}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={position}>
                        <Popup>
                            Museo Interactivo De Cultura Del Agua
                        </Popup>
                    </Marker>
                </MapContainer>
            </div>
        </>
    );
}

export default QueEsMica;
