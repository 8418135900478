import React from 'react';
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import "./BasicModal.scss"
import ModalHeader from "react-bootstrap/ModalHeader";

function BasicModal(props) {
    const { show, setShow, title, children } = props;

    return (
        <Modal className="basic-modal"
               show={show}
               onHide={() => setShow(false)}
               size="lg"
        >
            {children}
        </Modal>
    );
}

export default BasicModal;
